export const en = {
  title: {
    campaign_milestone: 'Campaign Milestone',
    add_milestone: 'Add a Milestone',
    anonymous_donor: 'Anonymous Donor',
    donor: 'Donor',
    is_on_the_way: 'is on the way',
    pending: 'Pending',
    completed: 'Completed',
    milestone_details: 'Milestone Details',
    new_progress: 'New Progress',
    new_invoice: 'Add New Invoice',
    progresses: 'Progresses',
    detail_invoice: 'Detail Invoice',
    enter_reply: 'Enter Reply',
  },
  button: {
    add_milestone: 'Add Milestone',
    add_campaign: 'Add Campaign',
    submit_milestone: 'Submit Milestone',
    edit_campaign: 'Edit Campaign',
    request_submit_campaign: 'Request to Submit Campaign',
    request_submit_item: 'Request to Submit Project Item',
    save_campaign: 'Save Campaign',
    apply_for_campaign: 'Apply for this Campaign',
    accept_this_vendor: 'Accept this Vendor',
    reject_this_vendor: 'Reject this Vendor',
    chat_with_super_admin: 'Chat with super admin',
    chat_with_vendor: 'Chat with vendor',
    chat_with_operator: 'Chat with operator',
    send: 'Send',

    milestones: {
      view_invoices: 'View Invoices',
      add_progress: 'Add Progress',
      view: 'View Milestone',
      close_view: 'Close View',
      submit_progress: 'Submit Progress',
      cancel: 'Cancel',
      check_progress: 'Progress Check',
      view_progress: 'View Progress',
      view_progresses: 'View Progresses',
      confirm_progress: 'Confirm Progress',
      reject_progress: 'Reject Progress',
      submit_invoice: 'Submit Invoice',
      view_payment: 'View Payment',
    },
    request_still_pending: 'This request is pending',
  },
  form: {
    name: {
      label: 'Name of The Campaign',
      required: 'Name of The Campaign is required',
    },
    project_id: {
      label: 'Select Parent Project',
      required: 'Parent Project is required',
    },
    campaign_type_id: {
      label: 'Campaign Type',
      required: 'Campaign Type is required',
    },
    only_money: {
      label: 'Campaign Only Money',
      required: 'Campaign Only Money is required',
      min_1: 'Campaign Only Money be greater than 0',
    },
    vendor_income: {
      label: "Vendor's Income",
      required: "Vendor's Income is required",
      min_1: "Vendor's Income be greater than 0",
      max_only_money: "Vendor's Income must be equals or lower than Only Money amount",
    },
    images: {
      label: 'Upload files',
      required: 'Upload files is required',
      max_files: 'You only allowed for upload 3 files',
    },
    milestone_name: {
      label: 'Milestone Name',
      required: 'Milestone Name is required',
    },
    process_name: {
      label: 'Progress Name',
      required: 'Progress Name is required',
    },
    deadline: {
      label: 'Deadline',
      required: 'Deadline is required',
    },
    details: {
      label: 'Details',
      required: 'Details is required',
    },
    progress_title: {
      label: 'Progress Title',
      required: 'Progress Title is required',
    },
    milestone: {
      label: 'Milestone',
    },
    type: {
      label: 'Type',
    },
    amount: {
      label: 'Amount',
    },
    transaction_code: {
      label: 'Transaction Code',
    },
    date: {
      label: 'Date',
    },
    donor_name: {
      label: 'Donor Name',
    },
    donation: {
      label: 'Donation',
    },
    counts: {
      label: 'Counts',
    },
    email: {
      label: 'Email',
    },
    vendor_name: {
      label: "Vendor's Name",
    },
    request_type: {
      label: 'Request Type',
    },
    attachments: {
      label: 'Milestone Name',
      required: 'Milestone Name is required',
    },
    invoice_name: {
      label: 'Invoice Name',
      required: 'Invoice Name is required',
    },
    invoice_amount: {
      label: 'Amount',
      min_1: 'Must be greater than 1',
      required: 'Amount is required',
      remaining_max: 'Your amount must be less than',
    },
    bank_id: {
      label: 'Bank Name',
      required: 'Bank Name is required',
    },
    bank_account_name: {
      label: 'Bank Account Name',
      required: 'Bank Account Name is required',
    },
    bank_account_number: {
      label: 'Bank Account Number',
      required: 'Bank Account Number is required',
      min: 'Bank Account Number must be 22 digit of numbers',
    },
  },
  options: {
    donors: {
      started_from: 'Started From',
      ends_to: 'Ends To',
      money_donated: 'Donated Money',
      count_donated: 'Donated Counts',
    },
  },
};
