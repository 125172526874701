import { en as menu } from 'src/layouts/translation/en';
import { en as response } from 'src/locales/response/en';
import { en as exception } from 'src/locales/exception/en';
import { en as section } from 'src/sections/translation/en';
import { en as auth } from 'src/sections/auth/translate/en';

const en = {
  roles: {
    CLUSTER_ADMIN: 'Admin',
    OPERATOR: 'Operator',
    EVALUATOR: 'Evaluator',
    VENDOR: 'Vendor',
    DONOR: 'Donor',
  },
  place_types: {
    HOSPITAL: 'Hospital',
    RESTAURANT: 'Restaurant',
    SCHOOL: 'School',
  },
  caption: {
    empty_data: 'No data found.',
    empty_notif: 'No notification found.',
    rows_per_page: 'Rows per page',
    dense: 'Dense table',
    type: {
      CAMPAIGN: 'Campaign',
      PROJECT: 'Project',
      MARKETPLACE: 'Marketplace',
    },
    status: {
      approved: 'Approved',
      finished: 'Finished',
      pending: 'Pending',
      rejected: 'Rejected',
      published: 'Published',
      default: 'Default',
      APPROVED: 'Approved',
      PENDING: 'Pending',
      SUCCESS: 'Success',
      CANCELLED: 'Cancelled',
      FAILED: 'Failed',
      DECLINED: 'Declined',
      EXPIRED: 'Expired',
      REJECTED: 'Rejected',
      DONE: 'Done',
      OPEN: 'Open',
      FORWARD: 'Forward',
      FORWARDED: 'Forwarded',
      CLOSED: 'Closed',
    },
    empty_campaign: 'Campaign is empty!',
    empty_description_campaign:
      'Look like your filtering or sorting is empty data in our database.',
    go_to_home: 'Go to Home',
    welcome_to_ommar: 'Welcome to Ommar',
    success_resend: 'Your email verification resent successfully',
    verify_email: 'Verify Email',
    resend_verification: 'Resend email verification',
    token_expired: 'Token has expired.',
    verify_your_account: 'Verify Your Account',
    please_verify_your_email: 'Please verify your email',
    success_registration: 'Your registration was successful',
  },
  form: {
    delete: {
      title: 'Delete',
      are_you_sure: 'Are you sure you want to delete?',
      delete_button: 'Delete',
      cancel_button: 'Cancel',
    },
    global: {
      are_you_sure: 'Are you sure',
      yes: 'Yes',
      no: 'No',
    },
  },
  notification: {
    success: {
      created_project: 'Project has been created!',
      created_campaign: 'Campaign has been created!',
      deleted_campaign: 'Campaign has been deleted!',
      approveed_project: 'Project has been approved!',
      rejected_project: 'Project has been rejected!',
      project_edit_request: 'Project has been submitted!',
      approveed_campaign: 'Campaign has been approved!',
      rejected_campaign: 'Campaign has been rejected!',
      campaign_edit_request: 'Campaign has been submitted!',
      created_item: 'Item has been created!',
      updated_item: 'Item has been updated!',
      rejected_item: 'Item has been rejected!',
      created_vendor: 'Vendor has been created!',
      edited_vendor: 'Vendor has been edited!',
      approve_vendor: 'Vendor has been approved!',
      reject_vendor: 'Vendor has been rejected!',
      created_operator: 'Operator has been created!',
      edited_operator: 'Operator has been updated!',
      deleted_operator: 'Operator has been deleted!',
      created_evaluator: 'Evaluator has been created!',
      edited_evaluator: 'Evaluator has been updated!',
      deleted_evaluator: 'Evaluator has been deleted!',
      created_donor: 'Donor has been created!',
      edited_donor: 'Donor has been updated!',
      deleted_donor: 'Donor has been deleted!',
      add_blog: 'Blog has been added!',
      edit_blog: 'Blog has been updated!',
      deleted_blog: 'Blog has been deleted!',
      comment_deleted: 'Comment has been deleted!',
      apply_campaign: 'Your request has been submitted!',
      approved_vendor_campaign: 'Vendor has been approved!',
      rejected_vendor_campaign: 'Vendor has been rejected!',
      add_progress_milestone: 'Your progress has been submitted!',
      accept_progress_milestone: 'Your progress has been accepted!',
      reject_progress_milestone: 'Your progress has been rejected!',
      add_invoice_progress: 'Your invoice has been submitted!',
      pay_to_vendor_invoice: 'Your payment has been submitted for that invoice!',
      edit_account_info: 'Your account has been updated!',
      create_new_ticket: 'Your ticket has been submitted!',
      approved_maintenance_request: 'Request has been approved!',
      rejected_maintenance_request: 'Request has been rejected!',
      deleted_vendor: 'Vendor has been deleted!',
      applied_to_maintenance: 'Your request has been submitted to Admin!',
      accept_evaluator_request: 'Evaluator request has been accepted!',
      reject_evaluator_request: 'Evaluator request has been rejected!',
      accept_evaluation_task: 'Evaluation task has been accepted!',
      reject_evaluation_task: 'Evaluation task has been rejected!',
      submit_evaluation_task: 'Your task has been submitted!',
      created_mosque_maintenance: 'Mosque maintenance has been created!',
      campaign_task_created: 'Campaign Task has been created!',
      campaign_task_edited: 'Campaign Task has been updated!',
    },
    error: {
      has_ticket_not_finish: 'You have unfinished ticket with this type!',
    },
  },
  auth,
  menu,
  section,
  response,
  exception,
};

export default en;
